.projectoption {
    width: 280px;
    display: flex;
    background: white;
    justify-content: center;
    margin: 10px 0px;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;
    color: #000;
    border-radius: 5px;
}

.projectoption a {
    text-decoration: none;
    color: #000;
}

.projectoption img {
    width: 100%;
    max-width: 300px;
}

.project-showcase {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fit, 300px);
}

.projects_case_container {
    padding: 10px 0px;
}

.projectDetails {
    flex-grow: 1;
}

.projimgshowcase {
    flex-shirnk: 0;
}


.projTitle {
    font-weight: 600;
    font-size: 20px;
}

.projActions button {
    background: #0d6efd;
    border: none;
    color: #fff;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.projectDetails {
    padding: 10px 20px;
}

.projDets {
    color: #888;
    font-size: 12px;
    margin: 10px 0px;
}

.projDets {
    display: flex;
    align-items: center;
}

.projStatus {
    padding: 2px 7px;
    margin-left: 5px;
}

.createFirstProject {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.createFirstProject button {
    flex-shrink: 0;
    margin-right: 10px;
    background: #0d6efd;
    border: none;
    color: #fff;
    padding: 15px 15px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 3px 3px 5px rgb(0 0 0 / 10%), -3px -3px 5px rgb(0 0 0 / 10%);
}

.projectoption:hover .projimgshowcase:before {
    background: rgba(0, 0, 0, .1);
}

.projimgshowcase:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    transition: .2s all ease-in-out;
}

.projimgshowcase {
    position: relative;
}

.projectoption {
    transition: .2s all ease-in-out;
}

.projectoption:hover {
    transform: translateY(-5px);
}