.animItem {
    display: inline-block;
    width: 100px;
    height: 120px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.animItem:hover {
    background: #cfe2ff;

}

.animName {
    height: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.anim_img {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.animItems {
    min-height: 190px;
    /* height: 190px; */
    overflow: auto;
    /* padding: 10px; */
    /* border: 1px solid rgba(0, 0, 0, .1); */
    border-radius: 10px;
    ;
}

.AnimPanel h5 {
    margin: 5px 0;
    padding: 0;
}

.advOptions .animItems h5 {
    padding-top: 10px;
}

.advOptions .animItems h5:first-child {
    padding-top: 0px;
}

.animFooter {
    display: flex;
    justify-content: space-between;
}

.animFooter span {
    display: inline-block;
    padding: 2px 10px;
    margin-top: 5px;
    background: #cfe2ff;
    border-radius: 20px;
    cursor: pointer;
}

.animFooter span.apply_options {
    background: #0d6efd;
    color: #fff;
}

.anim_slider_input {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px
}

.AnimPanel {
    padding: 5px 10px;
}

.anim_slider_input input {
    flex-grow: 1;
}

.anim_slider_input span {
    flex-shrink: 0;
    margin-left: 10px;
    display: inline-block;
    padding: 5px 10px;
    background: #deebff;
    border-radius: 5px;
    min-width: 60px;
    text-align: center;
}

.anim_slider_input:last-of-type {
    margin-bottom: 10px;
}

select.subAnimSelector {
    width: 100%;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.animItems {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fit, 110px);
}

.animFooter {
    position: sticky;
    bottom: 0px;
    padding: 10px;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.animItems.advanceWidth {
    display: block;
}