.subPagesList {
    position: absolute;
    z-index: 899;
    background: #fff;
    padding: 10px 0px;
    left: 0px;
    top: 40px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .2);
    display: none;
    padding-top: 0px;
}

.subPagesList.show {
    display: block;
}

.subPagesList ul {
    list-style-type: none;
    display: block;
    min-width: 185px;

}

.subPagesList ul li,
.subPagesList ul>button {
    display: block;
    width: 100%;
    cursor: pointer;
}


.subPagesList ul>hr {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin-top: 0px;
}

.selectPage {
    background: transparent;
    border: none;
    cursor: pointer;
}


.subPagesList ul>button {
    background-color: #cfe2ff;
    border-radius: 40px;
    color: #116dff;
    border: none;
    padding: 8px 10px;
    width: 80%;
    margin-left: 10%;
}

.subPagesList ul li {
    font-size: 12px;
}

.subPagesList ul li:hover {
    background-color: #cfe2ff;

}


.subPagesList ul a {
    text-decoration: none;
    color: #000;
    padding: 5px 20px;
    display: block;
}

.subPagesList ul li.active {
    background-color: #0d6efd;
    color: #fff;
}

.subPagesList ul li.active a {
    color: #fff;
}

.subPagesList .inList {
    height: 200px;
    overflow: auto;
    padding: 10px 0px;
    cursor: initial;
}

span.pageSelectorSpan:before {
    content: "Page: ";
    font-weight: initial;
    font-size: 12px;
}

span.pageSelectorSpan {
    font-size: 13px;
    font-weight: 500;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    cursor: pointer;
}


.subPagesList ul li.menuPagesList {
    display: flex;
}

.subPagesList ul li a {
    flex-grow: 1;
}

.subPagesList ul li button {
    flex-shrink: 0;
    border: none;
    background: transparent;
    color: red;
    background: #cccccc60;
    cursor: pointer;
}

.subPagesList ul li.active button {
    background: red;
    color: white;

}

.user-web-link {
    flex-grow: 1;
}

.user-web-link span {
    background: #f7f7f7;
    padding: 5px 20px;
    font-size: 12px;
    border-radius: 15px;
    width: 100%;
    display: block;
    padding-left: 30px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .1);
}

.user-web-link span:before {
    content: "\f57d";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    font-size: 15px;
    position: absolute;
    left: 20px;
}

a.external-open {
    position: absolute;
    right: 16px;
    top: 6px;
    font-size: 18px;
    background: #f7f7f7;

}

.user-web-link {
    position: relative;
    max-width: calc(100% - 890px);
    overflow: hidden;

}

.user-web-link span {
    padding-right: 50px;
    overflow: hidden;

}