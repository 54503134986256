.headingSettingsInner {
    padding: 10px
}

.headingSettingsInner h5 {
    margin: 0px;
}

.headingSettingsInner select {
    padding: 5px 10px;
    margin-top: 5px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 5px;
}