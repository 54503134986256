.container {
    display: flex;
}

.container>div {
    flex-shrink: 0;
}

.options_menu {
    min-height: calc(100vh - 40px);
    display: flex;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 10%);
    position: relative;
    z-index: 1;
}

.options_menu_main {
    flex-grow: 1;
    width: calc(100% - 20px);
}

.preview_panel {
    flex-grow: 1;
    height: calc(100vh - 40px);
}

.options_resizer {
    width: 10px;
    min-height: calc(100vh - 40px);
    background-color: #f7f7f7;
    flex-shrink: 0;
    justify-content: center;
    position: relative;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: w-resize;
}

.options_resizer i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.NavBar {
    z-index: 999;
    position: sticky;
}