body,
html {
  border: 0;
  padding: 0;
  margin: 0;
  outline: 0;
  font-family: 'Poppins', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  transition: .2s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

:root {
  --primary-link: #333;
  --primary-link-hover-background: #cfe2ff;
  --secondary-highlighter: #f7f7f7;
}