.item_drag {
    margin: 10px;
    float: left;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
}

.item_drag.half {
    max-width: 150px;
    max-height: 300px;
    overflow: hidden;
    position: relative;
    height: 120px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.item_drag:hover {
    color: #116dff;
    background-color: var(--primary-link-hover-background);
    outline: 1px solid #116dff;
    cursor: pointer;
}

.item_drag_img {
    max-width: 100%;
    text-align: center;
}

/* .item_drag:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    cursor: move;
} */