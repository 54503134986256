.url_prev {
    display: flex;
}

.url_prev span {
    background: #cccccc60;
    font-size: 12px;
    display: block;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-right: none;
}

.url_prev input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}