/* layout */
.wd {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
}

.wd-row {
    display: flex;
    width: 100%;
}

.wd-1 {
    width: 8.333333333333333333333%;
}

.wd-2 {
    width: calc(2 * 8.333333333333333333333%);
}

.wd-3 {
    width: calc(3 * 8.333333333333333333333%);
}

.wd-4 {
    width: calc(4 * 8.333333333333333333333%);
}

.wd-5 {
    width: calc(5 * 8.333333333333333333333%);
}

.wd-6 {
    width: calc(6 * 8.333333333333333333333%);
}

.wd-7 {
    width: calc(7 * 8.333333333333333333333%);
}

.wd-8 {
    width: calc(8 * 8.333333333333333333333%);
}

.wd-9 {
    width: calc(9 * 8.333333333333333333333%);
}

.wd-10 {
    width: calc(10 * 8.333333333333333333333%);
}

.wd-11 {
    width: calc(11 * 8.333333333333333333333%);
}

.wd-12 {
    width: calc(12 * 8.333333333333333333333%);
}

/* Navigation */
nav ul {
    list-style-type: none;
    margin: 0px;
}

nav ul {
    display: flex;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 5px 0px;
}

/* Responsive for preview */
div[data-prevpanel] {
    container: previewLayout / inline-size;
}

@container previewLayout (max-width: 768px) {
    .wd-row {
        flex-direction: column;
    }

    .wd {
        width: 100%;
    }
}

/* real life responsive */
@media only screen and (max-width: 768px) {
    .wd-row {
        flex-direction: column;
    }

    .wd {
        width: 100%;
    }
}