.txtPanelModes ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    background: #f7f7f7;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.txtPanelModes ul li {
    padding: 5px 10px;
    cursor: pointer;
    border-bottom: 2px solid #f7f7f7;
}

.txtPanelModes ul li.active {
    border-color: #0d6efd;
}

.txtSetting h5 {
    margin: 5px 0px;
}

.applyPanelOneSettings {
    text-align: right;
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 10px;
    position: sticky;
    bottom: 0px;
    background: #fff;
}

.applyPanelOneSettings button {
    background-color: #0d6efd;
    border-radius: 40px;
    color: #fff;
    border: none;
    padding: 5px 10px;

    cursor: pointer;
}