.custom-row {
    padding: 10px;
    position: sticky;
    top: 0px;
    z-index: 99;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    width: 100%;
}

.custom-row button {
    padding: 12px 10px;
    background-color: #0d6efd;
    border-radius: 5px;
    color: #fff;
    border: none;
    width: 100%;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, .2), -3px -3px 5px rgba(0, 0, 0, .2);
    cursor: pointer;
}

.custom-layouts {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fit, 160px);
}