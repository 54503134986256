.settingPanel {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.setingPanelMain {
    background: #fff;
    max-width: 600px;
    min-width: 400px;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-height: 400px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .2), -5px -5px 10px rgba(0, 0, 0, .2);
}